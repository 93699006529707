<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.applyDate') }}</span></div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.id') }}</span></div>
           <div>
             <input type="text" class="mr-5" v-model="reqData.adminId" :placeholder="$t('searchArea.id')" />
             <button class="btn-search" type="button" @click="pageSeach">
               <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <div class="tab-wrap">
        <button type="button" class="tab-item" :class="{ active: currentStatus === '0' }" @click="changeListType" value="0">{{ $t('button.subChargelist') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '2' }" @click="changeListType" value="2">{{ $t('button.subExchagelist') }}</button>
      </div>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody v-if="applyList.length !== 0">
            <tr v-for="(item, idx) in applyList" :key="idx">
              <td style="width: 15%">
                <span>{{ item.regDate }}</span>
              </td>
              <td>
                <span class="money-apply">{{ numberWithCommas(item.creditAmt) }}</span>
              </td>
              <td style="width: 15%" v-if="tabStatus === '1'">
                <span v-if="item.creditStatus === '1'">{{ item.updDate }}</span>
                <span v-else>-</span>
              </td>
              <td class="statusColor" :class="item.statusClass">{{ item.statusText }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">
                <span class="notice-tableDataEmpty">{{ $t('txt.noData') }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="applyList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import { creditUpdateStatus } from '@/api/member.js'
import { creditBotList } from '@/api/company.js'
import { getDateStr, replaceDateT } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import UiCheck from '@/components/ui/UiCheckSet'
import { creditDetail } from '@/api/partner'
import { getSiteData } from '@/libs/auth-helper'

export default {
  name: 'CreditApplyList',
  components: {
    TableHead,
    DateSelector,
    SearchFilter,
    Pagination,
    UiCheck
  },
  props: {
    status: {
      type: String,
      default: '0'
    }
  },
  watch: {
    $route: {
      async handler () {
        // this.currentStatus = this.$route.params.status || this.$route.query.status
        // await this.setTableData(1);
      }
    },
    tabStatus: {
      async handler (status) {
        console.log(status)
        if (status === '1') {
          this.headInfo.dataList = this.postDataList
        } else {
          this.headInfo.dataList = this.preDataList
        }
      }
    }
  },
  data: function () {
    return {
      siteInfo: null,
      tableName: 'Basic',
      headInfo: {
        fstColumn: false,
        dataList: ['applyDate', 'inAmt', 'transactiontime', 'transaction']
      },
      reqData: {
        startDate: '',
        endDate: '',
        creditStatus: '0',
        page: 1,
        count_per_list: '30',
        adminId: '',
        creditType: '1'
      },
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      pageInfo: {},
      applyList: [],
      checkList: [
        {
          val: '0',
          text: '요청'
        },
        {
          val: '1',
          text: '대기'
        },
        {
          val: '2',
          text: '완료'
        }
      ],
      tabStatus: 0,
      currentStatus: '',
      detailList: [],
      detailPageInfo: {},
      detailInfo: {
        page: 1
      }
    }
  },
  methods: {
    replaceDateT,
    async ptDetailOpen (item) {
      this.detailInfo = item
      await this.getDetailList(1)
    },
    async ptDetailClose () {
      this.detailInfo = null
      this.detailList = []
    },
    async getDetailList (page) {
      if (!page) {
        page = 1
        this.detailPageInfo.page = page
      }
      const params = {
        siteId: this.detailInfo.siteId,
        calculateIdx: this.detailInfo.calculateIdx,
        page
      }
      this.detailList = []

      await creditDetail(params).then(result => {
        console.log(result)
        if (result.resultCode === '0') {
          this.detailList = result.data.list
          this.detailPageInfo = result.data.pageInfo
        }
      })
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    pageSeach () {
      this.setTableData(1)
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    async getCreditApplyList (pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum
      }
      this.reqData.creditStatus = this.currentStatus
      const data = this.reqData
      return await creditBotList(data)
    },
    async changeListType (evt) {
      const target = evt.target
      let chooseType = target.value
      this.currentStatus = chooseType
      this.tabStatus = chooseType
      if (chooseType === '') {
        chooseType = 'all'
      }
      this.$router.replace({ params: {}, query: { status: chooseType } })
    },
    async setTableData (pageNum, status) {
      if (status) {
        this.currentStatus = status
      }
      const res = await this.getCreditApplyList(pageNum)
      const applyList = res.data.list
      const pageInfo = res.data.pageInfo
      if (pageInfo) {
        this.pageInfo = pageInfo
      }
      this.applyList = this.dataConvertor(applyList)
      console.log(this.applyList)
    },
    creditPayment (dataObj) {
      const _creditAmt = prompt('알 지급 금액', dataObj.creditAmt)
      const trigger = {
        msg: '',
        prog: false
      }
      if (_creditAmt) {
        if (isNaN(_creditAmt)) {
          trigger.msg = '숫자만 입력해주세요.'
        } else if (_creditAmt <= 0) {
          trigger.msg = '지급 최소 금액은 0 이상 입니다. 다시 입력해주세요.'
        } else if (_creditAmt == null || _creditAmt == undefined) {
          trigger.msg = ''
        } else {
          trigger.prog = confirm(`${this.numberWithCommas(_creditAmt)}을 지급합니다.`)
          if (trigger.prog) {
            trigger.msg = _creditAmt
          } else {
            trigger.msg = '알 지급 취소'
          }
        }
      } else {
        trigger.msg = ''
        trigger.prog = false
      }

      return trigger
    },
    async statusChange (idx, creditAmt, type, status, nextStatus) {
      const reqData = {
        creditIdx: idx,
        creditType: type,
        creditStatus: status,
        realCreditAmt: creditAmt
      }
      switch (status) {
        case '0':
          reqData.creditStatus = '2'
          break
        case '2':
          reqData.creditStatus = nextStatus.toString()
          if (type === '1' || type === '-1') {
            const preCheck = this.creditPayment(reqData)
            if (preCheck.prog) {
              reqData.realCreditAmt = preCheck.msg
            } else {
              if (preCheck.msg) {
                alert(preCheck.msg)
              }
              return
            }
          }
          break
        case '1':
          (reqData.creditStatus = '1'), (reqData.creditType = '-1')
          reqData.realCreditAmt = '0'
          break
      }
      const res = await creditUpdateStatus(reqData)
      this.setTableData()
      if (res.resultCode === '0') {
        if (reqData.creditStatus === '1') {
          alert('알 지급 완료')
        } else {
          alert('알 상태 변경 완료')
        }
      } else {
        if (reqData.creditStatus === '1') {
          alert('알 지급에 실패 했습니다. 다시 시도해주세요.')
        } else {
          alert('알 상태 변경에 실패 했습니다. 다시 시도해주세요.')
        }
      }
    },
    dataConvertor (dataList) {
      const _dataList = dataList
      if (!dataList.length || dataList.length === 0) {
        return _dataList
      }
      _dataList.forEach(items => {
        const creditStatus = items.creditStatus
        let statusText = ''
        // - 0:요청, 1:완료, 2:대기 , -1"거부
        if (creditStatus === '0') {
          statusText = '지급신청'
          items.statusClass = 'apply'
        } else if (creditStatus === '1') {
          statusText = '지급완료'
          items.statusClass = 'approve'
        } else if (creditStatus === '2') {
          statusText = '입금대기'
          items.statusClass = 'wait'
        } else if (creditStatus === '-1') {
          statusText = '지급거절'
          items.statusClass = 'refuse'
        }
        if (!items.realCreditAmt) {
          items.realCreditAmt = '-'
        }
        items.statusText = statusText
        items.regDate = items.regDate.replace('T', ' ')
        items.updDate = items.updDate.replace('T', ' ')
        for (const item in items) {
          if (items[item] === '') {
            items[item] = '-'
          }
        }
      })
      return _dataList
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    this.currentStatus = this.$route.query.status || this.status
    if (this.currentStatus === 'all') {
      this.currentStatus = ''
    }
    this.setStartDate()
    this.setEndDate()
    await this.setTableData()
  }
}
</script>

<style scoped>
.statusColor {
  font-weight: 700;
}
.statusColor.apply {
  color: #ef8c1d;
}
.statusColor.approve {
  color: blue;
}
.statusColor.refuse {
  color: red;
}
.statusColor.wait {
  color: green;
}
.mainTable tbody tr:nth-child(even) td {
  background: #f5f5f5;
}
</style>
